/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"elektroinstalace vyrobni hala"}>
        <Column className="pb--30 pt--30" name={"ifpp3bd4bze"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box fs--36 w--300" content={"Elektroinstalační práce ve výrobní hale"}>
              </Title>

              <Text className="text-box fs--20 w--500 lh--14" content={"Prováděné elektroinstalační práce probíhaly jak vně budovy (převážně osvětlení), tak i uvnitř budovy, a to jak ve výrobních, tak i v administrativních a společných prostorech. \n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--20" name={"yvft8nqfrj"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/5b4315dbbbc04aa9894a30af815f86af_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/5b4315dbbbc04aa9894a30af815f86af_s=350x_.JPG 350w, https://cdn.swbpg.com/t/11759/5b4315dbbbc04aa9894a30af815f86af_s=660x_.JPG 660w, https://cdn.swbpg.com/t/11759/5b4315dbbbc04aa9894a30af815f86af_s=860x_.JPG 860w, https://cdn.swbpg.com/t/11759/5b4315dbbbc04aa9894a30af815f86af_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/11759/5b4315dbbbc04aa9894a30af815f86af_s=2000x_.JPG 2000w"} position={{"x":"-31.33159268929504%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/11272d4902a0453584b7e3e597a4c37c_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/11272d4902a0453584b7e3e597a4c37c_s=350x_.JPG 350w, https://cdn.swbpg.com/t/11759/11272d4902a0453584b7e3e597a4c37c_s=660x_.JPG 660w, https://cdn.swbpg.com/t/11759/11272d4902a0453584b7e3e597a4c37c_s=860x_.JPG 860w, https://cdn.swbpg.com/t/11759/11272d4902a0453584b7e3e597a4c37c_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/11759/11272d4902a0453584b7e3e597a4c37c_s=2000x_.JPG 2000w"} position={{"x":"-21.409921671018278%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/d871e9f299c34b85b7e98bfaa5fd996d_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/d871e9f299c34b85b7e98bfaa5fd996d_s=350x_.JPG 350w, https://cdn.swbpg.com/t/11759/d871e9f299c34b85b7e98bfaa5fd996d_s=660x_.JPG 660w, https://cdn.swbpg.com/t/11759/d871e9f299c34b85b7e98bfaa5fd996d_s=860x_.JPG 860w, https://cdn.swbpg.com/t/11759/d871e9f299c34b85b7e98bfaa5fd996d_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/11759/d871e9f299c34b85b7e98bfaa5fd996d_s=2000x_.JPG 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/261b44056278489784cdcaeffd23c166_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/261b44056278489784cdcaeffd23c166_s=350x_.JPG 350w, https://cdn.swbpg.com/t/11759/261b44056278489784cdcaeffd23c166_s=660x_.JPG 660w, https://cdn.swbpg.com/t/11759/261b44056278489784cdcaeffd23c166_s=860x_.JPG 860w, https://cdn.swbpg.com/t/11759/261b44056278489784cdcaeffd23c166_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/11759/261b44056278489784cdcaeffd23c166_s=2000x_.JPG 2000w"} position={{"x":"0%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/b3549c8c3f37416ea46e74f320c9e24d_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/b3549c8c3f37416ea46e74f320c9e24d_s=350x_.JPG 350w, https://cdn.swbpg.com/t/11759/b3549c8c3f37416ea46e74f320c9e24d_s=660x_.JPG 660w, https://cdn.swbpg.com/t/11759/b3549c8c3f37416ea46e74f320c9e24d_s=860x_.JPG 860w, https://cdn.swbpg.com/t/11759/b3549c8c3f37416ea46e74f320c9e24d_s=1400x_.JPG 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/90017960eb43401b8fcae7105e48758c_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/90017960eb43401b8fcae7105e48758c_s=350x_.JPG 350w, https://cdn.swbpg.com/t/11759/90017960eb43401b8fcae7105e48758c_s=660x_.JPG 660w, https://cdn.swbpg.com/t/11759/90017960eb43401b8fcae7105e48758c_s=860x_.JPG 860w, https://cdn.swbpg.com/t/11759/90017960eb43401b8fcae7105e48758c_s=1400x_.JPG 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}